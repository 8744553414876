"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.widgetStyle = void 0;
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var lodash_1 = require("lodash");
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var widgetStyle = function (_a) {
    var e_1, _b;
    var _c;
    var blockStyle = _a.blockStyle, brandColors = _a.brandColors, overrides = _a.overrides, styleableProperties = _a.styleableProperties, paragraphColor = _a.paragraphColor;
    if (!styleableProperties) {
        return {};
    }
    var baseStyle = (0, lodash_1.merge)({}, blockStyle, overrides);
    var finalStyle = {};
    try {
        for (var styleableProperties_1 = __values(styleableProperties), styleableProperties_1_1 = styleableProperties_1.next(); !styleableProperties_1_1.done; styleableProperties_1_1 = styleableProperties_1.next()) {
            var property = styleableProperties_1_1.value;
            if (property.type === "color") {
                var defaultValue = property.defaultValue;
                if (defaultValue === "DERIVED_FROM_PARAGRAPH_HACK") {
                    defaultValue = !!paragraphColor ? (0, GetBlockStyle_1.convertColorObjToColor)(paragraphColor) : DefaultColors_1.DEFAULT_MIDNIGHT;
                }
                var styleColor = ((0, lodash_1.get)(baseStyle, property.path) || defaultValue);
                var color = "abs" in styleColor ? (0, GetBlockStyle_1.convertColorObjToColor)(styleColor) : styleColor;
                var sanitisedColor = (0, GetBlockStyle_1.sanitizeColor)(color !== null && color !== void 0 ? color : defaultValue, brandColors);
                var remarshaledColor = (0, GetBlockStyle_1.convertColorToColorObj)(sanitisedColor);
                (0, lodash_1.set)(finalStyle, property.path, remarshaledColor);
            }
            else {
                (0, lodash_1.set)(finalStyle, property.path, (_c = (0, lodash_1.get)(baseStyle, property.path)) !== null && _c !== void 0 ? _c : property.defaultValue);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (styleableProperties_1_1 && !styleableProperties_1_1.done && (_b = styleableProperties_1.return)) _b.call(styleableProperties_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return finalStyle;
};
exports.widgetStyle = widgetStyle;
