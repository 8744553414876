import "../CommonFrontend/AgreementView/AgreementModal.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/AgreementView/AgreementModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81VS27bMBDd5xTTRQCrDQ3JceJG3bRX6AUMiqSssSlSIClHRuG7FyQlS3bcNEDQoNBGHM7vvXkYzouHlSuKBfy6ASgo222MbhXPYU/NjBCmpTbEtqakTKzXWSFX2222Sb55b224MMRQjq0dAqLxZ7C9iMvKPrAjtqJcPw9R8USEFHvqUKv7MYSHkGfkrsohS9Nbf6wEbio3nI8384jiPqAYLhmVbOY9gMDjoulCIo62kfSQQylFNwldhlBvzCHzjnovTCn1MznkQFunB5tlRktJClHRPWqTA9PKUVSTXA8h15Aghwo5F1OHx+CAVsuANu9/ha+Bygp34qWhDNWGdHYkBAOORluMsSV2gnvTtrUOywOxQpY5MKGcMFcgA9S0IwOhy2XadBOGp6TNXmsCPsMiSWI6s0E1sHQCuYqSiiIptHO6ziFrOrBaIj/TV3Q6ZT8k0xn70U3Sfj2b8YUAnsJlD8XD9MMRyl2ifuhBj4KPnZxUHLKlyTR1XvmJ9qBGAafhu286iFhq7GaowJpNcXeRDRbp7R04Q5VtqBHKJfAJ60YbR32Lp1I0FBknTAurZRvlYSLu88nIcTAycOd004/SswdfLvwng9wmU5ALOBE530niROdCL1fIyc6bv6Z3AJ+AjBdCSmws2vOKpTY12S/Wa/8zOdq2qNGRonVOq/+kjVxpN5vHf0LQEo6WFlLw5GKB/lVPb674YbJ72RGrBNv5qhwtkxRrYX4wJhon+OSS6brRSii3XvujZ8UKKZj3ejcp72/hqpdDtosbH6V8VVH/srcPmO33WnCkMKtRDdtvlaZNF/V69h695Q042zTjE/CHZ+vFXjqONZ/6mpO1vEz7tXy8Of4G7+Y/tJgIAAA=\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var modalHeaderHeight = '62px';
export var modalInset = 'var(--spacing-xs__1bl7jj11i)';
export var navButtonColor = 'var(--b57tbb0)';
export var navButtonTextColor = 'var(--b57tbb1)';
export var container = 'b57tbb2';
export var body = 'b57tbb3';
export var content = 'b57tbb4';
export var headerTitle = 'b57tbb5';
export var wrapper = 'b57tbb6';
export var header = 'b57tbb7';
export var spinner = 'b57tbb8';
export var formNavButton = 'b57tbb9';
export var notification = 'b57tbba';