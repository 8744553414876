"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createListItemToken = exports.ListItemConfig = void 0;
var List_1 = require("@CommonFrontend/ContentTokens/Models/List");
var ListItem_1 = require("@CommonFrontend/ContentTokens/TokenRenderers/ListItem");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var styles = __importStar(require("@CommonFrontend/ContentTokens/SharedWidgetComponents/Text/ListItem.css"));
exports.ListItemConfig = {
    type: "listItem",
    tokenType: {},
    publicRender: function (_a) {
        var token = _a.token, innerHtml = _a.innerHtml, index = _a.index, tokens = _a.tokens, attributes = _a.attributes, style = _a.style;
        return (0, ListItem_1.createHtmlFromListItem)(token, { innerHtml: innerHtml, index: index, tokens: tokens }, attributes, style);
    },
    traits: {
        spacingShim: {
            className: "listitem-widget__spacing-shim",
        },
        styleable: {
            styleKey: "list",
            properties: [
                {
                    type: "enumerable",
                    label: "Marker style",
                    defaultValue: commonTypes_1.ListMarkerType.Circle,
                    options: [{ label: "Circle", value: commonTypes_1.ListMarkerType.Circle }, { label: "Square", value: commonTypes_1.ListMarkerType.Square }, { label: "Dash", value: commonTypes_1.ListMarkerType.Dash }],
                    path: "marker"
                },
                {
                    type: "color",
                    label: "Marker color",
                    defaultValue: "DERIVED_FROM_PARAGRAPH_HACK",
                    path: "markerColor",
                    variable: styles.listItemSharedVars.markerColor
                },
                {
                    type: "color",
                    label: "Text color",
                    defaultValue: "DERIVED_FROM_PARAGRAPH_HACK",
                    path: "color",
                    variable: styles.listItemSharedVars.color
                }
            ],
            hideInToolbar: true
        }
    },
};
var createListItemToken = function (_a) {
    var _b = _a === void 0 ? {} : _a, level = _b.level, type = _b.type;
    return ({
        type: "block",
        subType: "listItem",
        level: level !== null && level !== void 0 ? level : 1,
        listType: type !== null && type !== void 0 ? type : List_1.ListItemType.Bullet,
    });
};
exports.createListItemToken = createListItemToken;
